@tailwind base;
@tailwind components;
@tailwind utilities;

.cedarville-cursive-regular {
  font-family: "Cedarville Cursive", cursive;
  font-weight: 400;
  font-style: normal;
}

.backImage {
  background-image: url("./assets/images/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
